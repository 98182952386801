import { useJsApiLoader } from '@react-google-maps/api'
import { FC, useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Libraries } from '../../../types/@react-google-maps'
import { AppMainView } from '../../App/components/AppMainView'
import { ENVIRONMENT } from '../../App/constants/ENVIRONMENT'
import { useAppConfig } from '../../App/hooks/useAppConfig'
import { LoadingView } from '../../Common/components/LoadingView'
import { useEscapeKey } from '../../Common/hooks/useEscapeKey'
import { useLocalStorage } from '../../Common/hooks/useLocalStorage'
import { useRiders } from '../../Rider/hooks/useRiders'
import { useTasks } from '../../Task/hooks/useTasks'
import { useMyUser } from '../../User/hooks/useMyUser'
import { useWarehouses } from '../../Warehouse/hooks/useWarehouses'
import { auth } from '../helpers/firebase'
import { useHasPermission } from '../hooks/useHasPermission'
import { AuthAlertView } from './AuthAlertView'

const MAPS_LIBRARIES: Libraries = ['places']

export const AuthLoggedInView: FC = () => {
  const [authState, isLoadingAuthState] = useAuthState(auth)
  const hasPermission = useHasPermission()
  const authStateUserId = authState?.uid
  const subscribeMyUser = useMyUser((state) => state.subscribe)
  const unsubscribeMyUser = useMyUser((state) => state.unsubscribe)
  const isInitializingMyUser = useMyUser((state) => state.isInitializing)
  const subscribeAppConfig = useAppConfig((state) => state.subscribe)
  const unsubscribeAppConfig = useAppConfig((state) => state.unsubscribe)
  const isInitializingAppConfig = useAppConfig((state) => state.isInitializing)
  const subscribeWarehouses = useWarehouses((state) => state.subscribe)
  const unsubscribeWarehouses = useWarehouses((state) => state.unsubscribe)
  const isInitializingWarehouses = useWarehouses((state) => state.isInitializing)
  const subscribeEscapeKey = useEscapeKey((state) => state.subscribe)
  const unsubscribeEscapeKey = useEscapeKey((state) => state.unsubscribe)

  const subscribeRiders = useRiders((state) => state.subscribe)
  const unsubscribeRiders = useRiders((state) => state.unsubscribe)
  const subscribeTasks = useTasks((state) => state.subscribe)
  const unsubscribeTasks = useTasks((state) => state.unsubscribe)
  const selectedWarehouseId = useLocalStorage((state) => state.data.selectedWarehouseId)
  const mapsLoader = useJsApiLoader({
    googleMapsApiKey: ENVIRONMENT.firebaseConfig.apiKey,
    libraries: MAPS_LIBRARIES,
  })
  const user = useMyUser((store) => store.user)

  // Init Google Maps animations
  useEffect(() => {
    if (window.google) {
      require('marker-animate-unobtrusive').initializeGlobally()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.google])

  // No deps
  useEffect(() => {
    subscribeAppConfig()
    subscribeWarehouses()
    subscribeEscapeKey()

    return () => {
      unsubscribeAppConfig()
      unsubscribeWarehouses()
      unsubscribeEscapeKey()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Warehouse ID
  useEffect(() => {
    if (selectedWarehouseId) {
      subscribeRiders(selectedWarehouseId)
      subscribeTasks(selectedWarehouseId)
    }

    return () => {
      unsubscribeRiders()
      unsubscribeTasks()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWarehouseId])

  // User ID
  useEffect(() => {
    if (authStateUserId) {
      subscribeMyUser(authStateUserId)
    }

    return () => {
      unsubscribeMyUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStateUserId])

  const isInitializing =
    isLoadingAuthState ||
    isInitializingMyUser ||
    isInitializingWarehouses ||
    isInitializingAppConfig ||
    !mapsLoader.isLoaded

  if (isInitializing) {
    return <LoadingView />
  } else if (hasPermission('READ_ALL_WAREHOUSES') || user?.warehouseIds?.length) {
    return <AppMainView />
  } else {
    return <AuthAlertView title="No stores assigned to your user. Please contact the Zephyr support!" />
  }
}
